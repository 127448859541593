import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import { storyblokEditable } from 'gatsby-source-storyblok';

import { setActiveModal } from '../../state/ui/uiSlice';
import { getProductPath } from '../../lib/product';
import DynamicIcon from '../Icons/DynamicIcon';
import SmartLink from '../SmartLink';
import useFillOptions from '../useFillOptions';
import useMobile from '../useMobile';
import { track } from '../../lib/segmentAnalytics';

const DynamicButton = ({ blok }) => {
  const {
    text,
    link,
    openInNewWindow,
    style = 'default',
    action_type,
    action_payload,
    openInModal,
    closeModal,
    modalPosition,
    modalFill,
    icon,
    textStyle = 'capitalized',
    size = 'medium',
    fill = [],
    mobileFill = [],
    justify = 'between',
    product = {},
    forwardQueryParams,
    trackingEvent,
    ...rest
  } = blok;
  const { fillValue, fillValues } = useFillOptions(fill);
  const { fillValue: mobileFillValue, fillValues: mobileFillValues } =
    useFillOptions(mobileFill);
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.ui.activeModal);
  const isLinkStyle = style === 'link';
  const productUrl = product.item ? getProductPath(product.item) : undefined;
  const to = product.item
    ? productUrl
    : link.linktype === 'story'
    ? `/${link.cached_url}`
    : link.linktype === 'email'
    ? `mailto:${link.email}`
    : link.url;

  const plain = style === 'plain' || isLinkStyle;
  const primary = style === 'primary';
  const secondary = style === 'secondary';
  const isDefault = style === 'default' || style === 'buttonLink';
  const linkStyle = {
    width: isMobile
      ? mobileFillValues.horizontal
      : fillValues.horizontal
      ? '100%'
      : 'auto',
    height: isMobile
      ? mobileFillValues.vertical
      : fillValues.vertical
      ? '100%'
      : 'auto',
  };
  const fontStyle = textStyle
    ? { textTransform: textStyle === 'normal' ? 'none' : 'uppercase' }
    : {};

  return (
    <SmartLink
      to={openInModal ? undefined : to}
      forwardQueryParams={forwardQueryParams}
      target={openInNewWindow ? '_blank' : undefined}
      plain={plain}
      fill={isMobile ? mobileFillValue : fillValue}
      {...storyblokEditable(blok)}
      {...rest}
      style={linkStyle}
    >
      <Button
        {...rest}
        fill={isMobile ? mobileFillValue : fillValue}
        label={text}
        plain={plain}
        primary={primary}
        secondary={secondary}
        default={isDefault}
        justify={justify}
        size={size}
        reverse
        icon={icon && icon !== '' && <DynamicIcon type={icon} />}
        style={
          isLinkStyle || style === 'buttonLink'
            ? { textDecoration: 'underline', ...fontStyle, padding: 0 }
            : fontStyle
        }
        onClick={
          closeModal && activeModal
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (trackingEvent) {
                  track(trackingEvent);
                }
                dispatch(setActiveModal(null));
              }
            : openInModal
            ? () => {
                if (trackingEvent) {
                  track(trackingEvent);
                }
                dispatch(
                  setActiveModal({
                    fill: modalFill,
                    position: modalPosition,
                    story: link.id,
                  })
                );
              }
            : action_type
            ? () => {
                if (trackingEvent) {
                  track(trackingEvent);
                }
                dispatch({ type: action_type, payload: action_payload });
              }
            : trackingEvent
            ? () => {
                track(trackingEvent);
              }
            : undefined
        }
      />
    </SmartLink>
  );
};

DynamicButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.object,
  page: PropTypes.array,
  openInNewWindow: PropTypes.bool,
  style: PropTypes.oneOf([
    'primary',
    'secondary',
    'default',
    'plain',
    'link',
    'buttonLink',
  ]),
  action_type: PropTypes.string,
  action_payload: PropTypes.object,
  modal: PropTypes.array,
  openInModal: PropTypes.bool,
  closeModal: PropTypes.bool,
  modalPosition: PropTypes.string,
  modalFill: PropTypes.array,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textStyle: PropTypes.oneOf(['capitalized', 'normal']),
  size: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  fill: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  mobileFill: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  justify: PropTypes.oneOf([
    'around',
    'between',
    'center',
    'end',
    'evenly',
    'start',
    'stretch',
  ]),
  product: PropTypes.object,
  forwardQueryParams: PropTypes.bool,
  trackingEvent: PropTypes.string,
  blok: PropTypes.object,
};

export default DynamicButton;
