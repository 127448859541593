import isBrowser from './isBrowser';

export const track = (action, options) => {
  if (isBrowser) {
    if (window.analytics) {
      window.analytics.track(action, options);
    } else {
      window.analyticsQueue
        ? window.analyticsQueue.push({ action, options })
        : (window.analyticsQueue = [{ action, options }]);
    }
  }
};

export const identifyByEmail = (id) =>
  isBrowser
    ? window.analytics
      ? window.analytics.identify(id, { email: id })
      : undefined
    : undefined;
